var generic = generic || {};
var site = site || {};

site.client = site.client || {};

(function ($) {
  'use strict';

  var bp = {};
  var isMobileWidth = true;
  var animationTime = 100;

  Drupal.behaviors.siteHeaderWrapperV1 = {
    elems: {},
    active: false,
    $activeCategory: false,
    menuAnimationInterval: false,
    options: {
      enableHover: true,
      // Our buffer for when we mouseenter between overlay / menu
      // This is for a smoother user experience
      overlayHoverCloseDelay: 400
    },
    attach: function (context) {
      var self = this;
      var $module = $('.site-header__section', context);

      if (!$module.length) {
        return;
      }

      self.elems.$header = $module;
      self.elems.$menu = $('.js-nav-menu', self.elems.$header);
      self.elems.$categoryTrigger = $('.menu__link--has-children', self.elems.$header);
      // Start listening
      self.attachEventListeners();
      self.displayOffers();
    },

    attachEventListeners: function () {
      var self = this;
      var $stickyScrollTime = 150;
      var isMobile = Unison.fetch.now().name === 'small';

      Unison.on('change', function (currentBreakPoint) {
        isMobile = currentBreakPoint.name === 'small';
      });

      if (self.elems.$isStickyNav) {
        $stickyScrollTime = 10;
      }
      var triggerEvents = isMobile === true ? 'click.open-category' : 'click.open-category mouseenter.open-category';

      self.elems.$categoryTrigger.off(triggerEvents).on(triggerEvents, function (event) {
        var isValidHref = $(this).attr('href') && $(this).attr('href').trim() !== '#';

        if (event.type === 'click' && !isValidHref) {
          event.preventDefault();
        }
        // Do some validation to see if we should continue
        var $elem = $(this).siblings('.js-nav-category-trigger-content');

        // If we're opening a category on desktop, make sure any queued menu animation is cleared
        if (event.type === 'mouseenter') {
          // Set our closing animation
          clearTimeout(self.menuAnimationInterval);
        }
        // Break our closing animation
        // Open the category and pass this specific $categoryTrigger
        self.toggleCategory($(this));
      });
    },

    // Our dropdown content interaction

    // Close all of our content interaction
    closeContent: function () {
      var self = this;

      self.elems.$contentTriggers.removeClass('active');
      self.elems.$contentBlocks.removeClass('active');
      self.elems.$contentWrapper.stop(true, true).slideUp(animationTime);
      self.elems.$contentBlocks.addClass('hidden');
      self.elems.$globalOverlay.removeClass('active');
    },

    // This function slides down the category once a primary category is clicked
    toggleCategory: function ($category) {
      var self = this;
      // . menu__link--has-children-content is right next to category element
      var $elem = $category.siblings('.js-nav-category-trigger-content');
      // Determine if the element is already open
      var isOpen = !$elem.hasClass('nav-hidden');

      // If an existing element is open, close it and its parent
      // This will close the category that is open, including this category
      // However make sure that the clicked category is not a subcategory of the existing activeCategory
      if (self.$activeCategory !== false && !$.contains(self.$activeCategory[0], $category[0])) {
        self.$activeCategory.addClass('nav-hidden');

        // If the opening category is deeper than a top level category, only close the children within this
        if ($category.parents('li').length > 1) {
          self.$activeCategory.closest('li').removeClass('active');
          self.$activeCategory.closest('li').find('.js-nav-category-trigger-content').addClass('nav-hidden');
        } else {
          // When switching between two top level categories, just close everything
          self.closeMenus();
          // self.elems.$globalOverlay.removeClass('active');
        }
        self.$activeCategory = false;
      }
      // If wasn't open already, open it now
      if (!isOpen) {
        self.$activeCategory = $elem;
        self.$activeCategory.removeClass('nav-hidden').parents('li').addClass('active');
        // The block above adds .hidden to . menu__link--has-children
        // If the menus are siblings, this causes the parent . menu__link--has-children to be hidden
        // This forces all parent . menu__link--has-children to not be hidden
        self.$activeCategory.parents('.js-nav-category-trigger-content').removeClass('nav-hidden');
        // On desktop, our first category click triggers the gnav to open
        if (!isMobileWidth) {
          self.elems.$header.trigger('gnav.open');
        }
      }
    },

    // This closes all menu items and hides their content including and subsections
    closeMenus: function () {
      var self = this;

      // Close everything
      self.elems.$menu.find('li').removeClass('active');
      // Find all parent li elements and hide their content
      self.elems.$menu.find('.js-nav-category-trigger-content').addClass('nav-hidden');
    },

    displayOffers: function () {
      var self = this;
      var $promos = $('.basic-responsive', self.elems.$header);

      if ($promos.length < 2) {
        return;
      }

      setInterval(function () {
        // Have to re-select .basic-responsive rather than use $promos because
        // we're changing the order.
        $('.basic-responsive', self.elems.$header)
          .first()
          .slideUp(function () {
            $(this).appendTo($(this).parent()).css({
              overflow: '',
              display: ''
            });
          });
      }, 3000);
    }
  };
})(jQuery);
